import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select, { components } from 'react-select'
import hex2Rgba from 'hex-rgba'

import LogoAndKey from '../Home/CoinInfo/LogoAndKey'
import * as Colors from '../../utils/colors'
import ClosedSVG from './closed.svg'
import OpenSVG from './open.svg'

const NoMarginIcon = styled.img`
  margin-bottom: 0;
`

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <NoMarginIcon
          src={props.selectProps.menuIsOpen ? OpenSVG : ClosedSVG}
        />
      </components.DropdownIndicator>
    )
  )
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: Colors.WHITE,
    color: Colors.BLACK,
    fontWeight: state.isSelected ? 'bolder' : 'normal',
    border: `2px solid ${Colors.GREEN}`,
    borderTop: 0,
    cursor: 'pointer',
    '&:active': {
      background: `${hex2Rgba(Colors.GREEN, 20)}`,
    },
    '&:first-of-type': {
      borderTop: `1px solid ${Colors.GREEN}`,
    },
  }),
  control: (base, state) => ({
    ...base,
    flex: 1,
    border: 'none',
    borderBottom: `2px solid ${Colors.GREEN}`,
    boxShadow: 'none',
    borderRadius: `0`,
    background: 'transparent',
    color: state.selectProps.theme === 'dark' ? Colors.WHITE : Colors.BLACK,
    cursor: 'pointer',
    '&:hover': {
      borderColor: `${Colors.GREEN}`,
      boxShadow: 'none',
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    color: `${Colors.GREEN}`,
    padding: '8px 0',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  container: base => ({
    ...base,
    minWidth: '8rem',
    marginRight: '0.5rem',
    flex: '1',
  }),
  singleValue: (base, state) => ({
    ...base,
    lineHeight: '28px',
    fontSize: '20px',
    fontWeight: 'bold',
    color:
      state.selectProps.theme === 'dark'
        ? state.isSelected
          ? Colors.BLACK
          : Colors.WHITE
        : Colors.BLACK,
    margin: '0',
  }),
  menuList: base => ({
    ...base,
    borderRadius: `0`,
    padding: 0,
    marginTop: '-1px',
  }),
  menu: base => ({
    ...base,
    margin: 0,
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.selectProps.theme === 'dark' ? Colors.WHITE : Colors.BLACK,
  }),
  input: (base, state) => ({
    ...base,
    color: state =>
      state.selectProps.theme === 'dark' ? Colors.WHITE : Colors.BLACK,
  }),
  valueContainer: base => ({
    ...base,
    padding: '2px 0',
  }),
}

const CoinSelector = ({
  options = [],
  value = undefined,
  onChange = () => {},
  loading = false,
  theme = 'dark',
}) => {
  const [menuIsOpen, setMenuOpen] = React.useState(false)
  return (
    <Select
      className="react-select"
      styles={customStyles}
      value={menuIsOpen ? null : value}
      onChange={onChange}
      onBlur={() => setMenuOpen(false)}
      onFocus={() => setMenuOpen(true)}
      onMenuOpen={() => setMenuOpen(true)}
      onMenuClose={() => setMenuOpen(false)}
      options={options}
      isSearchable={true}
      getOptionLabel={opt => opt.key && <LogoAndKey coin={opt.key} />}
      getOptionValue={opt => (opt.key ? opt.key : opt)}
      isLoading={loading}
      theme={theme}
      placeholder=""
      components={{ DropdownIndicator }}
    />
  )
}

CoinSelector.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
}

export default CoinSelector
