import axios from 'axios'

const baseURL = (() => {
  if (process.env.GATSBY_BACKEND_URL !== undefined) {
    return process.env.GATSBY_BACKEND_URL
  } else {
    return 'http://localhost:3000'
  }
})()

console.log(`Using ${baseURL} as the backend URL!`)

export default axios.create({ baseURL, timeout: 100000 })
