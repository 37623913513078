import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../utils/colors'
import SocialIcons from '../../Icons/SocialIcons'

const MenuTitle = styled.h5`
  font-size: 28px;
  font-weight: normal;
  color: ${Colors.BLACK};
  margin-bottom: 2rem;
`

const SupportText = styled.div`
  line-height: 28px;
  font-size: 20px;
  color: ${Colors.GREY};
  margin-top: 1.5rem;
`

const SupportEmail = styled.a`
  line-height: 28px;
  font-size: 20px;
  color: ${Colors.GREEN};
  font-weight: bold;
`

export default () => (
  <>
    <MenuTitle><FormattedMessage id="FOOTER.BE_UP_TO_DATE" /></MenuTitle>
    <SocialIcons />
    <SupportText><FormattedMessage id="FOOTER.HAVING_AN_ISSUE" /></SupportText>
    <SupportEmail id="support-email" href="mailto:info@gostable.io"><FormattedMessage id="FOOTER.SUPPORT_EMAIL" /></SupportEmail>
  </>
)
