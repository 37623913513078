import React from 'react'
import styled from 'styled-components'

import CoinSelector from '../CoinSelector'
import Input from '../Input'
import CustomLoader from '../CustomLoader'

const InputCoinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;

  @media (max-width: 992px) {
    margin: 0;
    margin-top: 0.5rem;
  }
`

const InputCoinSelector = ({
  coin,
  setCoin,
  setValue,
  theme = 'dark',
  symbol,
}) => {
  return (
    <InputCoinWrapper>
      {coin.loading && <CustomLoader />}
      {!coin.loading && (
        <CoinSelector
          options={coin.available}
          value={coin.selected}
          onChange={v => setCoin(v)}
          loading={coin.loading}
          theme={theme}
        />
      )}
      <Input
        value={`${coin.value}`}
        onChange={ev => setValue(ev.target.value)}
        theme={theme}
        style={{ marginTop: 0 }}
        symbol={symbol}
      />
    </InputCoinWrapper>
  )
}

export default InputCoinSelector
