import React from 'react'
import styled from 'styled-components'

import Container from '../Container'
import LeftContent from './LeftContent'
import RightContent from './RightContent'

const StyledContainer = styled(Container)`
  display: flex;
  padding-top: 6rem;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    padding-bottom: 6rem;
  }
`

export default () => (
  <StyledContainer>
    <LeftContent />
    <RightContent />
  </StyledContainer>
)
