import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../../utils/colors'

const Button = styled.button`
  display: inline-block;
  border: none;
  border-radius: 0;
  padding: 1.3rem 2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  background: ${Colors.GREEN};
  color: ${Colors.LIGHT_GREY};
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
`

const SimpleButton = (props) => (
  <Button {...props}>{props.children}</Button>
)

export default SimpleButton