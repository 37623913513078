import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../../utils/colors'
import BGLogoWhite from './bg-logo-white.svg'
import BGLogo from './bg-logo.svg'

const DefaultWithLogo = styled.div`
  background-color: ${props => props.color};
  ${props => props.useImage ? `background-image: url('${props.logoColor === 'white' ? BGLogoWhite : BGLogo}');` : ''}
  background-repeat: no-repeat;
  background-position-y: ${props => props.yPos};
  background-position-x: ${props => props.xPos};
  background-size: initial;

  @media (max-width: 992px) {
    background-image: none;
  }
`

export default ({
  color = Colors.BACKGROUND,
  children,
  yPos = '80%',
  xPos = '0%',
  useImage = true,
  logoColor = 'white',
}) => (
  <DefaultWithLogo
    yPos={yPos}
    xPos={xPos}
    color={color}
    useImage={useImage}
    logoColor={logoColor}
  >
    {children}
  </DefaultWithLogo>
)

