import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import hex2Rgba from 'hex-rgba'

import * as Colors from '../../../utils/colors'
import { switchOriginAndTarget } from '../../../state/actions/coinValues'
import Icon from './switch.svg'

const Button = styled.button`
  background: ${Colors.GREEN};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: none;
  margin: 0 1.5rem;
  outline: none;
  cursor: pointer;

  &:active {
    background: ${hex2Rgba(Colors.GREEN, 80)};
  }
`

const SwicthIcon = styled.img`
  margin: 0;

  @media (max-width: 992px) {
    margin-right: 0;
  }
`

class SwitchCoinsButton extends React.Component {
  render() {
    const { switchOriginAndTarget } = this.props
    return (
      <Button
        type="button"
        onClick={() => switchOriginAndTarget()}
        {...this.props}
      >
        <SwicthIcon src={Icon} alt="Switch coins" />
      </Button>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  switchOriginAndTarget: () => dispatch(switchOriginAndTarget()),
})

const ConnectedSwitchCoinsButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchCoinsButton)

export default ConnectedSwitchCoinsButton
