export const GREEN='#00A9A5';
export const DARK_GREEN = '#007F7C'

export const WHITE="#fff"
export const LIGHT_GREY='#F9F8F8';
export const GREY='#75828A';
export const DARK_GREY='#BFBFBF';
export const BLACK='#1A1423'

export const RED='#DB5461';

/**
 * Background colors
*/
export const BACKGROUND='#F9F8F8'
