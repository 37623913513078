import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../utils/colors'

const StyledInput = styled.input`
  line-height: 28px;
  font-size: 20px;
  text-align: center;
  color: ${props =>
    props.theme === 'light' ? Colors.BLACK : Colors.LIGHT_GREY};
  outline: none;
  border: none;
  padding-bottom: 0.25rem;
  padding: 0.25rem 0;
  background: transparent;
  border-bottom: 2px solid ${Colors.GREEN};
  margin-top: 28px;

  @media (max-width: 350px) {
    max-width: 9rem;
  }
`

const Wrapper = styled.div`
  flex: 1;
  line-height: 28px;
  font-size: 20px;
  text-align: center;
  color: ${props =>
    props.theme === 'light' ? Colors.BLACK : Colors.LIGHT_GREY};
`

const Symbol = styled.div`
  position: absolute;
`

const input = props => {
  return (
    <Wrapper style={props.style} {...props}>
      <Symbol> {props.symbol} </Symbol>
      <StyledInput style={props.style} {...props} />
    </Wrapper>
  )
}
export default input
