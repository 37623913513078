import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Telegram from './svg/telegram.svg'

const Icon = styled.img`
  margin: 0.25rem 1.5rem 0.25rem 0;
`

export default () => <Icon src={Telegram} alt={<FormattedMessage id="ICONS.TELEGRAM" />} />