import React from 'react'
import styled from 'styled-components'

import FindYourWay from '../FindYourWay'
import BeUpToDate from '../BeUpToDate'

const RightContent = styled.div`
  flex: 1;
  display: flex;
  padding-left: 8rem;

  @media (max-width: 992px) {
    flex-direction: column;
    padding-left: 0;
  }
`

const ContentColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    margin-bottom: 3rem;
  }
`

export default () => (
  <RightContent>
    <ContentColumn style={{ marginRight: '2rem' }}>
      <FindYourWay />
    </ContentColumn>

    <ContentColumn>
      <BeUpToDate />
    </ContentColumn>
  </RightContent>
)
