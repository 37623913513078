import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Breakpoint from 'react-socks'

import Logo from '../Logo'
import Container from '../Container'
import * as Colors from '../../utils/colors'
import BurgerMenu from './BurgerMenu'
import NavbarLink from './NavbarLink'

const Background = styled.div`
  background: ${props =>
    props.theme === 'dark' ? Colors.BLACK : Colors.LIGHT_GREY};

  @media (min-width: 1350px) {
    background: linear-gradient(
      90deg,
      ${props => (props.theme === 'dark' ? Colors.BLACK : Colors.BACKGROUND)}
        95%,
      ${props =>
          props.theme === 'dark'
            ? Colors.BLACK
            : props.isHome
            ? Colors.WHITE
            : Colors.BACKGROUND}
        5%
    );
  }
`

const Wrapper = styled(Container)`
  padding: 1rem;
`

const MenuWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`

export default class Navbar extends React.Component {
  render() {
    const { showLogoDesktop, theme } = this.props
    return (
      <Background {...this.props}>
        <Wrapper>
          <Breakpoint medium down>
            <Link to="">
              <Logo color={theme === 'dark' ? 'white' : 'black'} />
            </Link>
            <BurgerMenu />
          </Breakpoint>

          <Breakpoint large up>
            <MenuWrapper>
              <Link to="">
                {showLogoDesktop && (
                  <Logo
                    color={theme === 'dark' ? 'white' : 'black'}
                    style={{ height: '1rem' }}
                  />
                )}
              </Link>
              <LinksContainer>
                <NavbarLink to="/exchange" text="HOME.MENU_EXCHANGE" />
                <NavbarLink
                  to="/transaction"
                  text="HOME.MENU_FOLLOW_TRANSACTION"
                />
              </LinksContainer>
            </MenuWrapper>
          </Breakpoint>
        </Wrapper>
      </Background>
    )
  }
}

Navbar.propTypes = {
  showLogoDesktop: PropTypes.bool,
  isHome: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
}

Navbar.defaultProps = {
  showLogoDesktop: false,
  isHome: false,
  theme: 'light',
}
