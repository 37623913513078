import * as types from '../types'
import backend from '../../utils/backend'

/**
 * Fetches coin and prices information from the backend API.
 *
 * @returns {Object} Object with the coins information and their prices.
 */
async function getCoinsAndPrices() {
  const res = await backend('prices')
  return res.data
}

/**
 * Dispatches the action to update the transaction values, based on the info provided.
 *
 * @param {Object} opts Transaction data.
 */
async function updateTransactionValues({
  dispatch,
  getState,
  coins = undefined,
  prices = undefined,
  originValue = undefined,
  targetValue = undefined,
}) {
  // Set information for the target coin
  return dispatch({
    type: types.UPDATE_TRANSACTION_VALUES,
    coins: coins || getState().coins,
    prices: prices || getState().prices,
    originCoin: getState().originCoin.selected,
    targetCoin: getState().targetCoin.selected,
    originValue,
    targetValue,
  })
}

/**
 * Helper method to change the input values, according to the type of the input
 * changed ('origin' or 'target').
 *
 * @param {Object} opts Transaction data.
 */
async function changeValue({ dispatch, getState, type, value }) {
  // Set the new value according to the type provided
  await dispatch({
    type:
      type === 'origin'
        ? types.SET_ORIGIN_COIN_VALUE
        : types.SET_TARGET_COIN_VALUE,
    value,
  })

  // Update transaction values
  await updateTransactionValues({
    dispatch,
    getState,
    originValue: type === 'origin' ? value : undefined,
    targetValue: type === 'origin' ? undefined : value,
  })
}

/**
 * Finds the prices from the backend API, manually sets the selected coins and
 * updates the transaction values for the selected coins and fetched prices.
 *
 * Action called in the initial load of the webapp.
 */
export function initialCoinSet() {
  return async (dispatch, getState) => {
    try {
      // Notify request start
      await dispatch({ type: types.INITIAL_COIN_SET_REQUEST })

      const data = await getCoinsAndPrices()
      console.log(data.coins)
      const stable = data.coins.filter(c => c.isStable)
      const eth = data.coins.findIndex(c => c.name === 'Ethereum') || 0

      // Set information for the origin coin
      await dispatch({
        type: types.INITIAL_COIN_SET_SUCCESS,
        prices: data.prices,
        coins: data.coins,
        originSelected: data.coins[eth],
        targetSelected: stable[0],
      })

      // Update transaction values
      await updateTransactionValues({
        dispatch,
        getState,
        prices: data.prices,
        coins: data.coins,
        originValue: getState().originCoin.value,
        targetValue: undefined,
      })
    } catch (error) {
      console.log('ERROR REQUESTING COIN PRICES')
      console.error(error)

      // Notify request finished
      dispatch({ type: types.INITIAL_COIN_SET_FAILURE })
    }
  }
}

/**
 * Finds the prices from the backend API, sets the selected origin coins and
 * updates the transaction values for the selected coins and fetched prices.
 *
 * Action called when the user selects a new origin coin.
 */
export function setOriginCoin(coin) {
  return async (dispatch, getState) => {
    try {
      // Set the selected origin coin
      await dispatch({ type: types.SET_ORIGIN_COIN_REQUEST, coin })

      // Find the updated prices
      const data = await getCoinsAndPrices()

      // Update transaction values
      await updateTransactionValues({
        dispatch,
        getState,
        prices: data.prices,
        coins: data.coins,
        originValue: getState().originCoin.value,
        targetValue: undefined,
      })
    } catch (error) {
      console.log('ERROR SETTING ORIGIN COIN PRICES')
      console.error(error)

      // Notify request finished
      dispatch({ type: types.SET_ORIGIN_COIN_FAILURE })
    }
  }
}

/**
 * Finds the prices from the backend API, sets the selected target coins and
 * updates the transaction values for the selected coins and fetched prices.
 *
 * Action called when the user selects a new target coin.
 */
export function setTargetCoin(coin) {
  return async (dispatch, getState) => {
    try {
      // Set the selected origin coin
      await dispatch({ type: types.SET_TARGET_COIN_REQUEST, coin })

      // Find the updated prices
      const data = await getCoinsAndPrices()

      // Update transaction values
      await updateTransactionValues({
        dispatch,
        getState,
        prices: data.prices,
        coins: data.coins,
        originValue: getState().originCoin.value,
        targetValue: undefined,
      })
    } catch (error) {
      console.log('ERROR SETTING TARGET COIN PRICES')
      console.error(error)

      // Notify request finished
      dispatch({ type: types.SET_TARGET_COIN_FAILURE })
    }
  }
}

/**
 * Updates the transaction values for the provided origin coin value and
 * the current selected coins.
 *
 * Action called when the user inputs a new origin coin value.
 */
export function changeOriginValue(value) {
  return async (dispatch, getState) => {
    try {
      await changeValue({ dispatch, getState, type: 'origin', value })
    } catch (error) {
      console.log('ERROR SETTING ORIGIN VALUE')
      console.error(error)

      // Notify request finished
      dispatch({ type: types.SET_ORIGIN_COIN_FAILURE })
    }
  }
}

/**
 * Updates the transaction values for the provided target coin value and
 * the current selected coins.
 *
 * Action called when the user inputs a new target coin value.
 */
export function changeTargetValue(value) {
  return async (dispatch, getState) => {
    try {
      await changeValue({ dispatch, getState, type: 'target', value })
    } catch (error) {
      console.log('ERROR SETTING TARGET VALUE')
      console.error(error)

      // Notify request finished
      dispatch({ type: types.SET_TARGET_COIN_FAILURE })
    }
  }
}

/**
 * Switches the options for origin and target coins.
 */
export function switchOriginAndTarget() {
  return (dispatch, getState) =>
    dispatch({
      type: types.SWITCH_COINS,
      prices: getState().prices,
      coins: getState().coins,
      originSelected: getState().originCoin.selected,
      targetSelected: getState().targetCoin.selected,
      originValue: getState().originCoin.value,
      targetValue: getState().targetCoin.value,
    })
}
