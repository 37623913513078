import React from 'react'
import styled from 'styled-components'

import LogoBlack from './logo-black.svg'
import LogoWhite from './logo-white.svg'

const StyledImg = styled.img`
  margin: 0;
`

export default (props) => (
  <StyledImg
    src={props.color === 'white' ? LogoWhite : LogoBlack}
    alt="GoStable Logo"
    height={props.height || 22.38}
    {...props}
  />
)

