import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../utils/colors'
import NavbarLink from '../../Navbar/NavbarLink'

const Wrapper = styled.div``

const MenuTitle = styled.h5`
  font-size: 28px;
  font-weight: normal;
  color: ${Colors.BLACK};
  margin-bottom: 2rem;
`

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const PaddedNavbarLink = styled(NavbarLink)`
  margin-bottom: 1.5rem;
`

export default () => (
  <Wrapper>
    <MenuTitle>
      <FormattedMessage id="FOOTER.FIND_YOUR_WAY" />
    </MenuTitle>
    <ItemsWrapper>
      <PaddedNavbarLink to="/exchange" text="HOME.MENU_EXCHANGE" />
      <PaddedNavbarLink to="/transaction" text="HOME.FOLLOW_TRANSACTION" />
    </ItemsWrapper>
  </Wrapper>
)
