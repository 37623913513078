import React from 'react'
import styled from 'styled-components'

import Medium from '../../Icons/Medium'
import Twitter from '../../Icons/Twitter'
import Telegram from '../../Icons/Telegram'

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default () => (
  <IconWrapper>
    <Medium />
    <Twitter />
    <Telegram />
  </IconWrapper>
)
