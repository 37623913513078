import React from 'react'
import styled from 'styled-components'
import { IntlProvider } from 'react-intl'
import { BreakpointProvider } from 'react-socks'

import * as Colors from '../../utils/colors'
import './layout.css'

// I18N - find the en messages and use them
import messages from '../../translations/en.json'

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/dist/locale-data/en') // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en') // Add locale data for de
}

const BGWrapper = styled.div`
  background: ${Colors.WHITE};
`

export default ({ children }) => (
  <BreakpointProvider>
    <IntlProvider locale={'en'} messages={messages}>
      <BGWrapper>{children}</BGWrapper>
    </IntlProvider>
  </BreakpointProvider>
)
