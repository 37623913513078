import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import BurgerClose from './svg/burger-close.svg'

const Icon = styled.img`
  margin: 0;
`

export default () => <Icon src={BurgerClose} alt={<FormattedMessage id="ICONS.MENU_CLOSE" />} />