import React from 'react'
import styled from 'styled-components'

import BTC from '../Logos/btc.svg'
import ETH from '../Logos/eth.svg'
import TUSD from '../Logos/tusd.svg'
import USDC from '../Logos/usdc.svg'
import PAX from '../Logos/pax.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const LogoImg = styled.img`
  width: 1.1rem;
  height: 1.1rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
`

const Text = styled.p`
  margin-bottom: 0;
`

export default ({ coin, name = undefined }) => {
  let logo
  switch (coin) {
    case 'BTC':
      logo = BTC
      break
    case 'ETH':
      logo = ETH
      break
    case 'TUSD':
      logo = TUSD
      break
    case 'USDC':
      logo = USDC
      break
    case 'PAX':
      logo = PAX
      break
    default:
      logo = `https://files.coinswitch.co/public/coins/${coin.toLowerCase()}.png`
      break
  }

  return (
    <Wrapper>
      <LogoImg src={logo} alt={name || coin} />
      <Text>{name || coin}</Text>
    </Wrapper>
  )
}
