import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Link } from "gatsby"
import { Location } from '@reach/router'

import * as Colors from '../../../utils/colors'

const MenuItem = styled(Link)`
  color: ${Colors.GREY};
  line-height: 28px;
  font-size: 20px;
  margin-right: 1.5rem;
  transition: all .2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-decoration: none;

  &:hover {
    color: ${Colors.GREEN};
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.active {
    font-weight: bold;
    border-bottom: 2px solid ${Colors.GREY};

    &:hover {
      border-bottom: 2px solid ${Colors.GREEN};
    }
  }
`

export default class NavbarLink extends React.Component {
  render () {
    const { text, to } = this.props
    return (
      <Location>
        {({ location }) => (
          <MenuItem className={location.pathname.startsWith(to) ? 'active' : ''} {...this.props}>
            <FormattedMessage id={text} />
          </MenuItem>
        )}
      </Location>
    )
  }
}
