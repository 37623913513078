import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Logo from '../../Logo'
import * as Colors from '../../../utils/colors'

const LeftContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 992px) {
    margin-bottom: 6rem;
    padding-left: 0;
  }
`

const StyledLogo = styled(Logo)`
  margin-bottom: 2rem;
`

const Copyrights = styled.span`
  color: ${Colors.GREY};
  opacity: 0.5;
`

export default () => (
  <LeftContent>
    <StyledLogo />
    <Copyrights><FormattedMessage id="FOOTER.COPYRIGHT_MESSAGE" /></Copyrights>
  </LeftContent>
)
