import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Burger from './svg/burger.svg'

const Icon = styled.img`
  margin: 0;
`

export default () => <Icon src={Burger} alt={<FormattedMessage id="ICONS.MENU" />} />