import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Medium from './svg/medium.svg'

const Icon = styled.img`
  margin: 0.25rem 1.5rem 0.25rem 0;
`

export default () => <Icon src={Medium} alt={<FormattedMessage id="ICONS.MEDIUM" />} />