import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../utils/colors'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 70px;
  height: 20px;
`

const CircleOne = styled.div`
  position: absolute;
  background: ${Colors.WHITE};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation: leftAndRight linear 1s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;

  @keyframes leftAndRight {
    0% {
      transform: translate(0px,0px);
    }
   
    50% {
      transform: translate(40px,0px);
    }
   
    100% {
      transform: translate(0px,0px);
    }
  }
`

const CircleTwo = styled.div`
  position: absolute;
  background: ${Colors.GREEN};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  animation: rightAndLeft linear 1s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;

  @keyframes rightAndLeft{
    0% {
      transform: translate(40px,0px);
    }
   
    50% {
      transform: translate(0px,0px);
    }
   
    100% {
      transform: translate(40px,0px);
    }
  }  
`

export default () => (
  <Wrapper>
    <CircleOne />
    <CircleTwo />
  </Wrapper>
)