import React from 'react'
import styled from 'styled-components'
import BurgerMenu from 'react-burger-menu'

import Burger from '../../Icons/Burger'
import BurgerClose from '../../Icons/BurgerClose'
import Logo from '../../Logo'
import FindYourWay from '../../Footer/FindYourWay'
import BeUpToDate from '../../Footer/BeUpToDate'
import * as Colors from '../../../utils/colors'

const burgerStyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '42px',
    height: '20px',
    right: '18px',
    top: '18px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: Colors.BACKGROUND,
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    right: '18px',
    top: '18px',
  },
  bmCross: {
    background: 'red'
  },
  bmMenuWrap: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
  },
  bmMenu: {
    background: Colors.BACKGROUND,
    padding: '18px',
  },
  bmItem: {
    outlineStyle: 'none',
  },
  bmOverlay: {
    background: Colors.BACKGROUND
  }
}

const StyledLogo = styled(Logo)`
  margin-bottom: 2rem;
`

const ResponsiveMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
`

export default () => (
  <BurgerMenu.slide
    id="slide"
    right
    styles={burgerStyles}
    customBurgerIcon={<Burger />}
    customCrossIcon={<BurgerClose />}
  >
    <StyledLogo />

    <ResponsiveMenuWrapper>
      <FindYourWay />
    </ResponsiveMenuWrapper>

    <ResponsiveMenuWrapper>
      <BeUpToDate />
    </ResponsiveMenuWrapper>
  </BurgerMenu.slide>
)
